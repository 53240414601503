import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  @Input() hidden: boolean;

  public menuOpen = false;
  public maxHeight = window.innerHeight - 101;
  public listOpacity = false;

  public data: any;
  public listItems: [string];
  public categories: any;
  public showCategoriesList = false;

  @Output() changeMap = new EventEmitter();

  constructor(
    private dataService: DataService,
  ) {
    if (window.innerWidth <= 520) {
      this.maxHeight -= 78;
    }
    this.setData();
  }

  ngOnInit() {

  }

  private setData() {
    this.data = this.dataService.getDataForSearchInfoList();
    this.setListItemsDefault();
    this.categories = this.data.map(loc => loc.category ? loc.category.trim() : "").filter(val => val);
    this.categories = Array.from(new Set(this.categories)).map(cat => ({ name: cat }));
  }

  private setListItemsDefault() {
    this.listItems = this.data.filter(loc => loc["premium listing"] === "Y" || loc.POI === "Y").map(loc => ({ name: loc["name on list"], highlight: loc.highlight }));
  }

  public onKey(val) {
    if (val.length) {
      val = val.toLowerCase();
      this.listItems = this.data.filter(loc => (
        (loc.category && loc.category.toLowerCase().includes(val)) ||
        (loc["name on map"].toLowerCase().includes(val)) ||
        (loc.tags.toLowerCase().includes(val))
      )).map(loc => ({ name: loc["name on list"], highlight: loc.highlight }));
      this.showCategoriesList = false;
    } else {
      this.setListItemsDefault();
    }
  }

  public toggleMenu() {
    this.menuOpen = !this.menuOpen;
    this.dataService.menuOpen = this.menuOpen;
    if (this.menuOpen) {
      setTimeout(() => {
        this.listOpacity = true;
      }, 800);
    } else {
      this.listOpacity = false;
    }
    let searchInput = document.getElementById("searchInput");
    if (this.menuOpen) {
      searchInput.focus();
    } else {
      searchInput.blur();
    }

    this.maxHeight = window.innerHeight - 101;
    if (window.innerWidth <= 520) {
      this.maxHeight -= 78;
    }

    if (!this.menuOpen) {
      this.showCategoriesList = false;
      this.setListItemsDefault();
    }

  }

  public catBtnClick() {
    this.showCategoriesList = !this.showCategoriesList;
    this.setListItemsDefault();
  }

  public onListItemClick(item) {
    if (this.showCategoriesList) {
      this.listItems = this.data.filter(loc => loc.category && loc.category.trim() == item.name).map(loc => ({ name: loc["name on list"], highlight: loc.highlight }));
      this.showCategoriesList = false;
    } else {
      let selectedLoc = this.data.filter(loc => loc["name on list"] == item.name)[0];
      this.changeMap.emit(selectedLoc);
      // this.toggleMenu();
    }
  }

}

