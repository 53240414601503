/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./list.component";
var styles_ListComponent = [i0.styles];
var RenderType_ListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListComponent, data: {} });
export { RenderType_ListComponent as RenderType_ListComponent };
function View_ListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No results available. "]))], null, null); }
function View_ListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onListItemClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "highlight": 0 }), (_l()(), i1.ɵted(3, null, [" ", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, ((_v.context.$implicit == null) ? null : _v.context.$implicit.highlight)); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((_v.context.$implicit == null) ? null : _v.context.$implicit.name); _ck(_v, 3, 0, currVal_1); }); }
export function View_ListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "list": 0, "show": 1 }), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { "max-height.px": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ListComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.listOpacity); _ck(_v, 1, 0, currVal_0); var currVal_1 = _ck(_v, 4, 0, _co.maxHeight); _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.listItems.length; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.listItems; _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, null, null, View_ListComponent_0, RenderType_ListComponent)), i1.ɵdid(1, 114688, null, 0, i3.ListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListComponentNgFactory = i1.ɵccf("app-list", i3.ListComponent, View_ListComponent_Host_0, { maxHeight: "maxHeight", listOpacity: "listOpacity", listItems: "listItems" }, { listItemClick: "listItemClick" }, []);
export { ListComponentNgFactory as ListComponentNgFactory };
