import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.css']
})
export class InfoBoxComponent implements OnInit {

  @Input() type: string; // Options 'userOutOfArea',
  @Input() visible: boolean;
  @Input() data: any;

  @Output() closeInfoBox = new EventEmitter();

  public fadeOut = false;

  constructor() {

  }

  ngOnInit() {

  }

  public openGoogleMap() {
    window.open("https://goo.gl/maps/3PtJA73g24vNX8eS9", "_self");
  }

  public closePopup() {
    this.fadeOut = true;
    this.closeInfoBox.emit(true);
  }

}
