<div *ngIf="visible" [ngClass]="{
  'infoBox': true, 
  'animated': true, 
  'fadeIn': visible,
  'fadeOut': fadeOut && !visible,
  'small': type == 'userOutOfArea', 
  'visible': visible
}">

  <span *ngIf="type == 'userOutOfArea'">
    <b>Would you like to get directions to “Butler Town Center”?</b>
    <br><br>
    <button (click)="closePopup()" class="noBtn mat-btn">NO</button>
    <button (click)="openGoogleMap()" class="yesBtn mat-btn">YES</button>

    <!-- <div style="display: flex;">
      <app-button (click)="closePopup()" text="NO" color="rgb(46, 46, 46)" margin="0px 20px 0px 50px" [border]="'none'">
      </app-button>
      <app-button (click)="openGoogleMap()" text="YES" color="rgb(37, 167, 37)" margin="0px 20px" [border]="'none'">
      </app-button>
    </div> -->

  </span>

</div>