import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var SplashScreenComponent = /** @class */ (function () {
    function SplashScreenComponent() {
        this.closed = false;
        this.baseLogoUrl = "assets/images/splash/slider";
        this.totalLogos = 3;
        this.logos = [];
    }
    SplashScreenComponent.prototype.ngOnInit = function () {
        this.initializeSlider();
    };
    SplashScreenComponent.prototype.closeSplash = function () {
        this.closed = true;
        setTimeout(function () {
            var sp = document.querySelector(".sp_screen");
            sp.style.display = "none";
        }, 800);
    };
    SplashScreenComponent.prototype.initializeSlider = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var i, _a;
            var _this = this;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        for (i = 1; i <= this.totalLogos; i++) {
                            this.logos.push({ url: this.baseLogoUrl + "/" + i + ".png", class: "fadeInRight" });
                        }
                        _a = this;
                        return [4 /*yield*/, this.getRandomItem(this.logos)];
                    case 1:
                        _a.currentBanner = _b.sent();
                        setInterval(function () {
                            _this.currentBanner.class = "fadeOutLeft";
                            setTimeout(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                                var _a;
                                return tslib_1.__generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.getRandomItem(this.logos)];
                                        case 1:
                                            _a.currentBanner = _b.sent();
                                            this.currentBanner.class = "fadeInRight";
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, 800);
                        }, 5000);
                        return [2 /*return*/];
                }
            });
        });
    };
    SplashScreenComponent.prototype.getRandomItem = function (items) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var randIndex = function (items) { return Math.floor(Math.random() * items.length); };
            var randNum = randIndex(items);
            var recursive = function () {
                if (_this.currentBanner && items[randNum] && items[randNum].url === _this.currentBanner.url) {
                    randNum = randIndex(items);
                    recursive();
                }
                else {
                    resolve(items[randNum]);
                }
            };
            recursive();
        });
    };
    return SplashScreenComponent;
}());
export { SplashScreenComponent };
