import data from '../../../assets/data/searchListInfo2';
import * as i0 from "@angular/core";
var DataService = /** @class */ (function () {
    function DataService() {
        this.menuOpen = false;
    }
    DataService.prototype.getDataForSearchInfoList = function () {
        return data.WorkingCopy;
    };
    DataService.ngInjectableDef = i0.defineInjectable({ factory: function DataService_Factory() { return new DataService(); }, token: DataService, providedIn: "root" });
    return DataService;
}());
export { DataService };
