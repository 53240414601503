import { Injectable } from '@angular/core';
import data from '../../../assets/data/searchListInfo2';

export interface loc {
  "BTC": string
  "category": string
  "geometry": string
  "latitude": string
  "longitude": string
  "name": string
  "name on map": string
  "premium listing": string
  "status": string
  "tags": string
  "zoom": string
  "altitude": string
  "description": string
  "website": string
  "menu url": string
  "provide directions": string
  "street address": string
  "city": string
  "state": string
  "zip code": string
  "mon hours": string
  "tues hours": string
  "wed hours": string
  "thurs hours": string
  "fri hours": string
  "sat hours": string
  "sun hours": string
  "ph #": string
  "plaza": string
  "opening": string
  "name on list": string
  "directions URL": string
  "360 showcase url": string

}

@Injectable({
  providedIn: 'root'
})

export class DataService {

  public menuOpen = false;

  constructor() {

  }

  public getDataForSearchInfoList() {
    return data.WorkingCopy;
  }

}
