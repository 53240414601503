<app-splash-screen>
</app-splash-screen>

<app-menu [hidden]="hideMenu" (changeMap)="onMapChange($event, true)"></app-menu>

<span [ngClass]="{'animated locBtn': true, 'fadeOut': hideMenu || dataService.menuOpen, 'fadeIn': !hideMenu}">
    <img (click)="setUserLocation()" *ngIf="!hideMenu && !dataService.menuOpen" height="45" width="45"
        src="assets/icons/gpsBtn.png">
</span>
<span [ngClass]="{'animated instaBtn': true, 'fadeOut': hideMenu || dataService.menuOpen, 'fadeIn': !hideMenu}">
  <a *ngIf="!hideMenu && !dataService.menuOpen" href="https://www.instagram.com/shopatbutler/" target="_blank" rel="noopener noreferrer">
    <img height="45" width="45" src="assets/icons/instaBtn.png">
  </a>
</span>
<span [ngClass]="{'animated fbBtn': true, 'fadeOut': hideMenu || dataService.menuOpen, 'fadeIn': !hideMenu}">
  <a *ngIf="!hideMenu && !dataService.menuOpen" href="https://www.facebook.com/ShopatButler/" target="_blank" rel="noopener noreferrer">
    <img height="45" width="45" src="assets/icons/fbBtn.png">
  </a>
</span>
<span [ngClass]="{'animated calendarBtn': true, 'fadeOut': hideMenu || dataService.menuOpen, 'fadeIn': !hideMenu}">
    <a *ngIf="!hideMenu && !dataService.menuOpen" href="https://www.shopatbutler.com/events-at-butler/" target="_blank" rel="noopener noreferrer">
        <img height="45" width="45" src="assets/icons/eventsBtn.png">
    </a>
</span>

<app-info-box type="userOutOfArea" [visible]="popUpOpen" [data]="" (closeInfoBox)="onPopUpClose($event)"></app-info-box>

<mgl-map [style]="style" [zoom]="[zoom]" [center]="center" [pitch]="pitch" (load)="onMapLoad($event)">
    <mgl-marker *ngIf="showUserLocationMarker" [lngLat]="markerLngLat">
        <div class="dot"></div>
    </mgl-marker>

    <mgl-popup *ngIf="infoBoxOpen" anchor="top" [offset]="2" [lngLat]="infoBox.point" [closeOnClick]="true" (close)="onInfoBoxClose($event)">
        <div class="infoBox">
            <h2 class="heading">{{infoBox['name']}}</h2>
            <h4 class="heading" *ngIf="infoBox.status">{{infoBox['status']}}</h4>

            <span *ngIf="infoBox.description">
                <p>{{infoBox.description}}</p>
            </span>
            <br *ngIf="infoBox.description">
            <span *ngIf="infoBox.opening_hours">
                <details>
                    <summary>
                        <b>Hours: {{infoBox.opening_hours[0].day}} - {{infoBox.opening_hours[0].hrs}}</b>
                    </summary>
                    <p style="margin: 0;" *ngFor="let hrsData of infoBox.opening_hours">
                        {{hrsData.day}} -- {{hrsData.hrs}}
                    </p>
                </details>
            </span>

            <br *ngIf="infoBox.opening_hours">
            <br *ngIf="infoBox.opening_hours">
            <span *ngIf="infoBox['360 showcase url']">
                <a class="btn mat-btn" href="{{infoBox['360 showcase url']}}" target="_blank" rel="noopener noreferrer">360 Showcase</a>
            </span>
            <br *ngIf="infoBox['360 showcase url']">
            <span *ngIf="infoBox['ph #']">
                <a class="btn mat-btn" href="tel:{{infoBox['ph #']}}">Call</a>
            </span>
            <span *ngIf="infoBox['menu url']">
                <a class="btn mat-btn" href="{{infoBox['menu url']}}" target="_blank" rel="noopener noreferrer">Menu</a>
            </span>
            <span *ngIf="infoBox.website">
                <a class="btn mat-btn" href="{{infoBox.website}}" target="_blank" rel="noopener noreferrer">Website</a>
            </span>
            <span *ngIf="infoBox['provide directions'] === 'y'">
              <a class="btn mat-btn"
                    href="http://maps.google.com/maps?z=17&t=m&q=loc:{{infoBox.latitude}}+{{infoBox.longitude}}">Directions</a>
          </span>

            <span *ngIf="infoBox['directions url']">
                <a class="btn mat-btn"
                    href="{{infoBox['directions url']}}" target="_blank" rel="noopener noreferrer">Directions</a>
            </span>


        </div>
    </mgl-popup>

    <!-- <mgl-marker [lngLat]="[testPoints[0].longitude,  testPoints[0].latitude]">

    </mgl-marker>
    <mgl-marker [lngLat]="[testPoints[1].longitude,  testPoints[1].latitude]">

    </mgl-marker>
    <mgl-marker [lngLat]="[testPoints[2].longitude,  testPoints[2].latitude]">

    </mgl-marker>
    <mgl-marker [lngLat]="[testPoints[3].longitude,  testPoints[3].latitude]">

    </mgl-marker> -->

</mgl-map>
