import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  @Input() maxHeight: number;
  @Input() listOpacity: boolean;
  @Input() listItems: any;
  @Output() listItemClick = new EventEmitter();

  constructor() {

  }

  ngOnInit() {

  }

  public onListItemClick(item) {
    this.listItemClick.emit(item);
  }

}
