<div (click)="closeSplash()" [ngClass]="{'sp_screen': true, 'animated': true, 'fadeOut': closed}">

    <div class="logo desktop">
        <img src="assets/images/splash/butlersplash.svg" alt="">
    </div>
    <div class="banner desktop">
        <!-- <img src="assets/images/splash/dm-logo-2.png" alt=""> -->

        <img [ngClass]="{'animated': true, 'fadeOutLeft': currentBanner?.class == 'fadeOutLeft',
    'fadeInRight': currentBanner?.class == 'fadeInRight' }" src="{{currentBanner?.url}}">

    </div>

    <div class="logo mobile">
        <img src="assets/images/splash/butlersplash.svg" alt="">
    </div>
    <div class="banner mobile">
        <!-- <img src="assets/images/splash/dm-logo-2.png" alt=""> -->

        <img [ngClass]="{'animated': true, 'fadeOutLeft': currentBanner?.class == 'fadeOutLeft',
    'fadeInRight': currentBanner?.class == 'fadeInRight' }" src="{{currentBanner?.url}}">

        <!-- <slideshow [height]="'25vh'" [minHeight]="" [autoPlay]="true" [showArrows]="false" [imageUrls]="logos"
      [lazyLoad]="logos?.length > 1" [autoPlayWaitForLazyLoad]="true">
    </slideshow> -->

    </div>

</div>
