/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./info-box.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./info-box.component";
var styles_InfoBoxComponent = [i0.styles];
var RenderType_InfoBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfoBoxComponent, data: {} });
export { RenderType_InfoBoxComponent as RenderType_InfoBoxComponent };
function View_InfoBoxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Would you like to get directions to \u201CButler Town Center\u201D?"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "noBtn mat-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["NO"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "yesBtn mat-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openGoogleMap() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["YES"]))], null, null); }
function View_InfoBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "infoBox": 0, "animated": 1, "fadeIn": 2, "fadeOut": 3, "small": 4, "visible": 5 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoBoxComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, true, _co.visible, (_co.fadeOut && !_co.visible), (_co.type == "userOutOfArea"), _co.visible); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type == "userOutOfArea"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_InfoBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfoBoxComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InfoBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-info-box", [], null, null, null, View_InfoBoxComponent_0, RenderType_InfoBoxComponent)), i1.ɵdid(1, 114688, null, 0, i3.InfoBoxComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfoBoxComponentNgFactory = i1.ɵccf("app-info-box", i3.InfoBoxComponent, View_InfoBoxComponent_Host_0, { type: "type", visible: "visible", data: "data" }, { closeInfoBox: "closeInfoBox" }, []);
export { InfoBoxComponentNgFactory as InfoBoxComponentNgFactory };
