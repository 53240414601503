import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { loc, DataService } from 'src/app/services/data/data.service';
import { Map, GeolocateControl, Marker, LngLat, LngLatLike } from 'mapbox-gl';
import { bounds } from 'src/assets/data/areaBounds';
import * as geoLib from 'geolib';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {

  public map: Map;
  public zoom: number = 15.5;
  public center: any = [-82.376059, 29.626085];
  public pitch: number = 51;
  public style: string = "mapbox://styles/sitetour360/cjtsnxx2f0uxb1fmgr33td4ct";
  public popUpOpen = false;


  public markerLngLat: LngLatLike;
  public showUserLocationMarker = false;
  private userOutsideAreaPopupDisplayed = false;
  private watchPos = false;
  private watchPosId: number;
  private lastPos: any;
  public hideMenu = false;

  // Info Box data
  public infoBox: loc;
  public infoBoxOpen = false;
  public opening_hours: any;
  public today: number = new Date().getDay();

  public testPoints = bounds;
  private dpPoint: any;
  private dpPitch: any;

  constructor(
    public dataService: DataService,
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit() {

  }

  public onMapLoad(mapInstance: Map) {
    this.map = mapInstance;
    this.updateCursorStyling();
    this.mobileGestureHandling();
    this.map.on("click", (ev => {
      // console.log(ev.lngLat);
      let features = this.map.queryRenderedFeatures(ev.point).filter(f => f.layer.type === "symbol");
      let feature = features[0];
      if (!feature) return;
      let locations = this.dataService.getDataForSearchInfoList();
      let clickedLoc = locations.filter(l => l.name === feature.properties.name || l["name on map"] === feature.properties["name on map"])[0];
      this.onMapChange(clickedLoc, false);

    }));
  }

  public onMapChange(loc: any, locClickedFromMenu: boolean) {
    let locIsInsideArea: boolean = true;
    let dot: LngLatLike = [parseFloat(loc.longitude), parseFloat(loc.latitude)];
    if (!locClickedFromMenu) {
      this.map.flyTo({ center: dot, zoom: 17 });
    } else {
      locIsInsideArea = geoLib.isPointInside({ latitude: parseFloat(loc.latitude), longitude: parseFloat(loc.longitude) }, bounds);
      if (locIsInsideArea) {
        this.map.flyTo({ center: dot, zoom: 17 });
      } else {
        this.map.flyTo({ center: this.center, zoom: 17 });
      }
    }

    this.showInfoBox(loc, locIsInsideArea);
  }

  public showInfoBox(infoBox: loc, locIsInsideArea) {
    this.setOpeningHours(infoBox);
    infoBox["locIsInsideArea"] = locIsInsideArea;
    infoBox["point"] = locIsInsideArea ? [parseFloat(infoBox.longitude), parseFloat(infoBox.latitude)] : this.center;
    this.infoBox = infoBox;
    this.hideMenu = true;
    this.infoBoxOpen = true;
    this.cdr.detectChanges();
    if (!locIsInsideArea) {
      let anchor = <HTMLElement>document.querySelector(".mapboxgl-popup-tip");
      anchor.style.display = "none";
    }
  }

  public onInfoBoxClose(ev) {
    this.hideMenu = false;
    this.infoBoxOpen = false;
  }

  public setUserLocation() {
    this.lastPos = undefined;
    this.userOutsideAreaPopupDisplayed = false;
    if (this.watchPos) {
      window.navigator.geolocation.clearWatch(this.watchPosId);
      this.watchPos = false;
    }
    if (window.navigator.geolocation) {
      if (!this.watchPos) {
        this.watchPosId = window.navigator.geolocation.watchPosition(this.setLoc.bind(this), this.onErrorGettingUserLoc, { enableHighAccuracy: false });
        this.watchPos = true;
      } else {
        window.navigator.geolocation.getCurrentPosition(this.setLoc.bind(this), this.onErrorGettingUserLoc);
      }
    };
  }

  private setLoc(position) {
    if (this.lastPos && this.lastPos.latitude == position.coords.latitude && this.lastPos.longitude == position.coords.longitude) return;
    let pos = { latitude: position.coords.latitude, longitude: position.coords.longitude };
    // A point inside area for testing
    // let pos = { latitude: 29.62615, longitude: -82.375318 };

    let diffFromLastPosReceieved: number;
    if (this.lastPos) {
      diffFromLastPosReceieved = geoLib.getDistance(this.lastPos, pos);
    }
    this.lastPos = pos;
    let userIsInsideArea = geoLib.isPointInside(pos, bounds);
    if (userIsInsideArea && (!diffFromLastPosReceieved || diffFromLastPosReceieved >= 5)) {
      let dot: LngLatLike = [pos.longitude, pos.latitude];
      this.map.flyTo({ center: dot, zoom: 17 });
      this.markerLngLat = dot;
      this.showUserLocationMarker = true;
    } else if (!userIsInsideArea && !this.userOutsideAreaPopupDisplayed) {
      this.popUpOpen = true;
      this.userOutsideAreaPopupDisplayed = true;
    }
  }

  private onErrorGettingUserLoc(error) {
    console.log(error);
  }

  public onPopUpClose(close) {
    if (close) {
      this.popUpOpen = false;
    }
  }

  private setOpeningHours(locData: loc) {
    if (locData["mon hours"] || locData["tues hours"] || locData["wed hours"] ||
      locData["thurs hours"] || locData["fri hours"] || locData["sat hours"] || locData["sun hours"]) {
      locData["opening_hours"] = [
        { day: "Sunday", hrs: locData["sun hours"] },
        { day: "Monday", hrs: locData["mon hours"] },
        { day: "Tuesday", hrs: locData["tues hours"] },
        { day: "Wednesday", hrs: locData["wed hours"] },
        { day: "Thursday", hrs: locData["thurs hours"] },
        { day: "Friday", hrs: locData["fri hours"] },
        { day: "Saturday", hrs: locData["sat hours"] }
      ];
      let starting = locData["opening_hours"].filter((v, i) => i >= this.today);
      let ending = locData["opening_hours"].filter((v, i) => i < this.today);
      locData["opening_hours"] = starting.concat(ending);
    }
  }

  // Issue: cursor styling over custom layer
  // https://github.com/mapbox/mapbox-gl-js/issues/1353
  private updateCursorStyling() {
    this.map.on("mousemove", e => {
      let allFeatures = this.map.queryRenderedFeatures(e.point);
      let symbolFeatures = allFeatures.filter(f => f.layer.type === "symbol" && f.properties["name on map"]);
      this.map.getCanvas().style.cursor = symbolFeatures.length ? "pointer" : "";
    })
  }

  // Issue: add mobile gesture handling for pitch #3405
  // https://github.com/mapbox/mapbox-gl-js/issues/3405
  private mobileGestureHandling() {
    let self = this;
    this.map.on('touchstart', function (data) {
      if (data.points.length == 2) {
        var diff = Math.abs(data.points[0].y - data.points[1].y);
        if (diff <= 50) {
          data.originalEvent.preventDefault();   //prevent browser refresh on pull down
          self.map.touchZoomRotate.disable();    //disable native touch controls
          self.map.dragPan.disable();
          self.dpPoint = data.point;
          self.dpPitch = self.map.getPitch();
        }
      }
    });

    this.map.on('touchmove', function (data) {
      if (self.dpPoint) {
        data.preventDefault();
        data.originalEvent.preventDefault();
        var diff = (self.dpPoint.y - data.point.y) * 0.5;
        self.map.setPitch(self.dpPitch + diff);
      }
    });

    this.map.on('touchend', function (data) {
      if (self.dpPoint) {
        self.map.touchZoomRotate.enable();
        self.map.dragPan.enable();
      }
      self.dpPoint = null;
    });

    this.map.on('touchcancel', function (data) {
      if (self.dpPoint) {
        self.map.touchZoomRotate.enable();
        self.map.dragPan.enable();
      }
      self.dpPoint = null;
    });
  }

}
