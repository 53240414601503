/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./menu.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../list/list.component.ngfactory";
import * as i4 from "../list/list.component";
import * as i5 from "./menu.component";
import * as i6 from "../../services/data/data.service";
var styles_MenuComponent = [i0.styles];
var RenderType_MenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MenuComponent, data: {} });
export { RenderType_MenuComponent as RenderType_MenuComponent };
function View_MenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.catBtnClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "catBtn mat-btn": 0, "show": 1 }), (_l()(), i1.ɵted(-1, null, [" Categories "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.listOpacity); _ck(_v, 1, 0, currVal_0); }, null); }
function View_MenuComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-list", [], null, [[null, "listItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("listItemClick" === en)) {
        var pd_0 = (_co.onListItemClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ListComponent_0, i3.RenderType_ListComponent)), i1.ɵdid(1, 114688, null, 0, i4.ListComponent, [], { maxHeight: [0, "maxHeight"], listOpacity: [1, "listOpacity"], listItems: [2, "listItems"] }, { listItemClick: "listItemClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.maxHeight; var currVal_1 = _co.listOpacity; var currVal_2 = (_co.showCategoriesList ? _co.categories : _co.listItems); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_MenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { "menu animated": 0, "fadeOut": 1, "fadeIn": 2 }), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "menuInner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "form", [["id", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, [["search", 1]], null, 2, "input", [["autocomplete", "off"], ["id", "searchInput"], ["name", "input"], ["type", "text"]], [[8, "placeholder", 0]], [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.onKey(i1.ɵnov(_v, 5).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(7, { "input": 0, "square": 1 }), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["id", "searchBtn"], ["type", "reset"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { "search": 0, "close": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MenuComponent_2)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, true, _co.hidden, !_co.hidden); _ck(_v, 1, 0, currVal_0); var currVal_2 = _ck(_v, 7, 0, true, _co.menuOpen); _ck(_v, 6, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, true, _co.menuOpen); _ck(_v, 9, 0, currVal_3); var currVal_4 = (_co.menuOpen && !_co.hidden); _ck(_v, 12, 0, currVal_4); var currVal_5 = (_co.menuOpen && !_co.hidden); _ck(_v, 14, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.menuOpen ? "" : "Type your search here"); _ck(_v, 5, 0, currVal_1); }); }
export function View_MenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-menu", [], null, null, null, View_MenuComponent_0, RenderType_MenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.MenuComponent, [i6.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MenuComponentNgFactory = i1.ɵccf("app-menu", i5.MenuComponent, View_MenuComponent_Host_0, { hidden: "hidden" }, { changeMap: "changeMap" }, []);
export { MenuComponentNgFactory as MenuComponentNgFactory };
