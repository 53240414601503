let data = {
  "WorkingCopy": [
    {
      "latitude": "29.625559",
      "longitude": "-82.376189",
      "altitude": "",
      "geometry": "Point",
      "name": "Whole Foods",
      "name on map": "Whole Foods",
      "category": "Grocery",
      "zoom": "1",
      "premium listing": "Y",
      "directions url": "https://goo.gl/maps/yzNv7wAboJgPLeSd6",
      "name on list": "Whole Foods",
      "description": "Eco-minded chain with natural & organic grocery items, housewares & other products.",
      "tags": "grocery, groceries, deli, health food, organic, amazon pickup, butcher, produce, ",
      "website": "https://www.wholefoodsmarket.com/stores/gainesville",
      "street address": "3490 SW Archer Rd",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "8am-10pm",
      "tues hours": "8am-10pm",
      "wed hours": "8am-10pm",
      "thurs hours": "8am-10pm",
      "fri hours": "8am-10pm",
      "sat hours": "8am-10pm",
      "sun hours": "8am-10pm",
      "ph #": "352-244-8302",
      "plaza": "BTC",
      "icon": "wf",
      "highlight": true
  },
  {
      "latitude": "29.626415",
      "longitude": "-82.37513",
      "geometry": "Point",
      "name": "Cheesecake Factory",
      "name on map": "Cheesecake Factory",
      "category": "Dining",
      "zoom": "1",
      "premium listing": "Y",
      "status": "Coming Soon!",
      "name on list": "Cheesecake Factory - Opening 2019",
      "opening": "Q2 2019",
      "tags": "asian, american, fast food, breakfast, lunch, dinner, fine dining, casual dining, mexican, irish, chinese, event, venue, banquet, food, dining, restaurant, eat, meals, pizza, burgers, sandwiches, seafood, bar food, pub food, sushi",
      "website": "https://www.thecheesecakefactory.com/",
      "menu url": "https://www.thecheesecakefactory.com/menu/",
      "provide directions": "y",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC",
      "highlight": true
  },
  {
      "latitude": "29.62716",
      "longitude": "-82.375835",
      "geometry": "Point",
      "name": "PF Chang's",
      "name on map": "PF Chang's",
      "category": "Dining",
      "zoom": "1",
      "premium listing": "Y",
      "directions url": "https://goo.gl/maps/JvYKdwEYd5szipb56",
      "name on list": "PF Chang's",
      "description": "P.F. Chang's offers authentic Chinese food & Asian cuisine in a casual dining atmosphere.",
      "tags": "food, dining, restaurant, eat, meals, asian, lunch, dinner, fine dining, casual dining, chinese, event, bar food, pub food, sushi",
      "website": "https://www.pfchangs.com/locations/us/fl/gainesville/3597-sw-32nd-ct/9802-butler-town-center.html",
      "menu url": "https://www.pfchangs.com/menu?storeid=9802",
      "street address": "3597 SW 32nd Terrace Ste 10",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "11am-10pm",
      "tues hours": "11am-10pm",
      "wed hours": "11am-10pm",
      "thurs hours": "11am-10pm",
      "fri hours": "11am-11pm",
      "sat hours": "11am-11pm",
      "sun hours": "11am-10pm",
      "ph #": "352-570-7785",
      "plaza": "BTC",
      "highlight": true
  },
  {
      "latitude": "29.627089",
      "longitude": "-82.377223",
      "altitude": "",
      "geometry": "Point",
      "name": "Regal Cinema",
      "name on map": "Regal Cinema",
      "category": "Entertainment",
      "zoom": "1",
      "premium listing": "Y",
      "directions url": "https://goo.gl/maps/a1aY9y3QNY57YdNT6",
      "name on list": "Regal Cinema",
      "description": "Theater complex with multiple screens featuring new release films, plush seating & concession stand.",
      "tags": "movies, cinema, ",
      "website": "https://www.regmovies.com/",
      "street address": "3101 SW 35th Blvd",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC"
  },
  {
      "latitude": "29.624715",
      "longitude": "-82.376495",
      "geometry": "Point",
      "name": "Tesla Charging Station",
      "name on map": "Tesla Charging Station",
      "category": "Amenity",
      "zoom": "1",
      "POI": "Y",
      "directions url": "https://goo.gl/maps/vkaLSNTXMdMKVhU39",
      "name on list": "Tesla Charging Station",
      "description": "Electric vehicle charging station.",
      "tags": "charger, EV, tesla, hybrid, electric, supercharger station",
      "360 showcase url": "https://www.sitetour360.com/pano/tesla/",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC"
  },
  {
      "latitude": "29.626389",
      "longitude": "-82.375971",
      "geometry": "Point",
      "name": "Irish 31",
      "name on map": "Irish 31",
      "category": "Dining",
      "zoom": "3",
      "status": "Coming Soon!",
      "name on list": "Irish 31 - Opening 2019",
      "opening": "Q2 2019",
      "description": "Irish 31 combines the comforts of an Irish Pub with a chef-inspired menu, and live music to give guests a neighborhood bar and restaurant experience.",
      "tags": "food, dining, restaurant, eat, meals, lunch, dinner, casual dining, irish, sandwiches, bar food, pub food,",
      "website": "https://irish31.com/",
      "menu url": "https://irish31.com/menu/",
      "provide directions": "y",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC"
  },
  {
      "latitude": "29.625224",
      "longitude": "-82.377598",
      "altitude": "",
      "geometry": "Point",
      "name": "Bonefish Grill",
      "name on map": "Bonefish Grill",
      "category": "Dining",
      "zoom": "3",
      "directions url": "https://goo.gl/maps/5R2JCWgSqE2kZE1v8",
      "name on list": "Bonefish Grill",
      "description": "Contemporary grill chain offering a seafood-centric menu, plus steaks & cocktails.",
      "tags": "food, restauraunt, dining, eat, ",
      "website": "https://www.bonefishgrill.com/locations/fl/gainesville",
      "menu url": "http://bonefishgrill.blob.core.windows.net/menu/BM2.pdf",
      "street address": "3237 SW 35th Blvd",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "4pm-10pm",
      "tues hours": "4pm-10pm",
      "wed hours": "4pm-10pm",
      "thurs hours": "4pm-10pm",
      "fri hours": "4pm-11pm",
      "sat hours": "11am-11pm",
      "sun hours": "10am-9pm",
      "ph #": "352-377-8383",
      "plaza": "BTC"
  },
  {
      "latitude": "29.625971",
      "longitude": "-82.374729",
      "altitude": "",
      "geometry": "Point",
      "name": "Grub Burger Bar",
      "name on map": "Grub Burger",
      "category": "Dining",
      "zoom": "3",
      "directions url": "https://goo.gl/maps/f1bFijbgnBL2EKiMA",
      "name on list": "Grub Burger Bar",
      "description": "Casual burger joint that also offers salads, sandwiches, bowls, shakes, and a full bar. ",
      "tags": "american, fast food, lunch, dinner, casual dining, burgers, sandwiches, bar food, pub food, food, dining, restaurant, eat, meals,",
      "website": "https://grubburgerbar.com/stores/gainesville/",
      "menu url": "https://grubburgerbar.com/wp-content/uploads/2019/03/Main-Menu-No-pricing-2019.pdf",
      "street address": "2917 SW 35th Dr",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "11am-10pm",
      "tues hours": "11am-10pm",
      "wed hours": "11am-10pm",
      "thurs hours": "11am-10pm",
      "fri hours": "11am-11pm",
      "sat hours": "11am-11pm",
      "sun hours": "11am-10pm",
      "ph #": "352-505-1990",
      "plaza": "BTC"
  },
  {
      "latitude": "29.625178",
      "longitude": "-82.376271",
      "geometry": "Point",
      "name": "Mosaic Fountain",
      "name on map": "Mosaic Fountain",
      "category": "Amenity",
      "zoom": "3",
      "POI": "Y",
      "name on list": "Mosaic Fountain",
      "description": "Artist: Linda Zidonik",
      "tags": "art, fountain, water feature, mosaic fountain by Linda Zidonik",
      "360 showcase url": "https://www.sitetour360.com/pano/fountain/",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC"
  },
  {
      "latitude": "29.626087",
      "longitude": "-82.374872",
      "geometry": "Point",
      "name": "Chase Bank",
      "name on map": "Chase Bank",
      "category": "Financial",
      "zoom": "4",
      "status": "Coming Soon!",
      "name on list": "Chase Bank - Opening 2019",
      "opening": "Q2 2019",
      "tags": "lending, bank, home loans, auto loans, drivethru, ",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC"
  },
  {
      "latitude": "29.626046",
      "longitude": "-82.375616",
      "geometry": "Point",
      "name": "Noire The Nail Bar",
      "name on map": "Noire Nail Bar",
      "category": "Health & Beauty",
      "zoom": "4",
      "status": "Coming Soon!",
      "name on list": "Noire The Nail Bar - Opening 2019",
      "opening": "Q2 2019",
      "tags": "manicure, beauty, pedicure, spa, nails, ",
      "provide directions": "y",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "plaza": "BTC"
  },
  {
      "latitude": "29.625992",
      "longitude": "-82.375536",
      "geometry": "Point",
      "name": "European Wax Center",
      "name on map": "European Wax Center",
      "category": "Health & Beauty",
      "zoom": "4",
      "directions url": "https://goo.gl/maps/8WfU1qMTFfbEpMba7",
      "name on list": "European Wax Center",
      "description": "Our waxing center offers hair waxing services such as Brazilian waxing, eyebrow waxing, upper lip waxing, face, and full-body waxing.",
      "tags": "waxing, salon, beauty",
      "website": "https://www.waxcenter.com/fl-gainesville-butler-town-center",
      "street address": "2850 SW 35th Dr",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "9am-8pm",
      "tues hours": "9am-8pm",
      "wed hours": "9am-8pm",
      "thurs hours": "9am-8pm",
      "fri hours": "9am-8pm",
      "sat hours": "9am-6pm",
      "sun hours": "10am-4pm",
      "ph #": "352-204-9820",
      "plaza": "BTC"
  },
  {
      "latitude": "29.626981",
      "longitude": "-82.375833",
      "geometry": "Point",
      "name": "Heart & Hearth",
      "name on map": "Heart & Hearth",
      "category": "Home",
      "zoom": "4",
      "directions url": "https://goo.gl/maps/AyWfE7Drf4CjJCs98",
      "name on list": "Heart & Hearth",
      "tags": "knick knacks, décor, southern living, kitchen, home décor, gifts, clothing, boutique",
      "website": "https://www.hearthheart.com/",
      "360 showcase url": "https://www.sitetour360.com/hh",
      "street address": "3597 SW 32nd Ct Suite 20",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "12pm-6pm",
      "tues hours": "12pm-6pm",
      "wed hours": "12pm-6pm",
      "thurs hours": "12pm-6pm",
      "fri hours": "12pm-9pm",
      "sat hours": "12pm-9pm",
      "sun hours": "12pm-6pm",
      "ph #": "352-240-6280",
      "plaza": "BTC"
  },
  {
      "latitude": "29.626094",
      "longitude": "-82.376269",
      "altitude": "",
      "geometry": "Point",
      "name": "The Village Jeweler",
      "name on map": "Village Jeweler",
      "category": "Jewelry",
      "zoom": "4",
      "directions url": "https://goo.gl/maps/DMhhNCei3KawxGS29",
      "name on list": "The Village Jeweler (Fine Jewelery)",
      "description": "Long-running jeweler featuring designer pieces, plus custom work & in-house repair services.",
      "tags": "jewelry, diamonds, rings, custom jewelry, ",
      "website": "https://www.thevillagejeweler.com/",
      "360 showcase url": "https://www.sitetour360.com/thevillagejeweler",
      "street address": "3625 SW 32nd Court Suite 50",
      "status": "Fine Jewelery",
      "city": "Gainesville",
      "state": "FL",
      "zip code": "32608",
      "mon hours": "10am-6pm",
      "tues hours": "10am-6pm",
      "wed hours": "10am-6pm",
      "thurs hours": "10am-6pm",
      "fri hours": "10am-6pm",
      "sat hours": "10am-5pm",
      "sun hours": "12pm-5pm",
      "ph #": "352-338-0015",
      "plaza": "BTC"
  }
]
}

export default data;
