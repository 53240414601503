import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { environment } from 'src/environments/environment';
import { MapComponent } from './components/map/map.component';
import { MenuComponent } from './components/menu/menu.component';
import { ListComponent } from './components/list/list.component';
import { DataService } from './services/data/data.service';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { ButtonComponent } from './components/button/button.component';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SlideshowModule } from 'ng-simple-slideshow';

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    MenuComponent,
    ListComponent,
    InfoBoxComponent,
    ButtonComponent,
    SplashScreenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMapboxGLModule.withConfig({
      accessToken: environment.mboxToken,
      geocoderAccessToken: environment.mboxToken
    }),

  ],
  providers: [DataService],
  bootstrap: [AppComponent]
})
export class AppModule { }
