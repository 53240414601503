import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.css']
})
export class SplashScreenComponent implements OnInit {

  public closed: boolean = false;
  private baseLogoUrl: string = "assets/images/splash/slider";
  private totalLogos: number = 3;
  private logos: Array<any> = [];

  public currentBanner: any;
  private lastIndex: number;

  constructor() { }

  ngOnInit() {
    this.initializeSlider();
  }

  public closeSplash() {
    this.closed = true;
    setTimeout(() => {
      let sp = <HTMLElement>document.querySelector(".sp_screen");
      sp.style.display = "none";
    }, 800);
  }

  private async initializeSlider() {
    for (let i = 1; i <= this.totalLogos; i++) {
      this.logos.push({ url: `${this.baseLogoUrl}/${i}.png`, class: "fadeInRight" });
    }
    this.currentBanner = await this.getRandomItem(this.logos);
    setInterval(() => {
      this.currentBanner.class = "fadeOutLeft";
      setTimeout(async () => {
        this.currentBanner = await this.getRandomItem(this.logos);
        this.currentBanner.class = "fadeInRight";
      }, 800);
    }, 5000)
  }

  private getRandomItem(items) {
    return new Promise((resolve, reject) => {
      let randIndex = items => Math.floor(Math.random() * items.length);
      let randNum = randIndex(items);
      let recursive = () => {
        if (this.currentBanner && items[randNum] && items[randNum].url === this.currentBanner.url) {
          randNum = randIndex(items);
          recursive();
        } else {
          resolve(items[randNum]);
        }
      };
      recursive();
    });
  }

}
