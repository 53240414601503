<div [ngStyle]="{'max-height.px': maxHeight}" [ngClass]="{'list': true, 'show': listOpacity }">

  <ul>
    <li *ngIf="!listItems.length">
      No results available.
    </li>
    <li (click)="onListItemClick(item)" [ngClass]="{'highlight': item?.highlight}" *ngFor="let item of listItems">
      {{item?.name}}</li>
  </ul>

</div>