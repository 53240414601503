<div [ngClass]="{'menu animated': true, 'fadeOut': hidden, 'fadeIn': !hidden }">
  <div class="menuInner">
    <form id="content">
      <input [placeholder]="!menuOpen ? '' : 'Type your search here' " autocomplete="off"
        [ngClass]="{'input': true, 'square': menuOpen}" #search (keyup)="onKey(search.value)" id="searchInput"
        type="text" name="input">
      <button [ngClass]="{'search': true, 'close': menuOpen}" (click)="toggleMenu()" id="searchBtn"
        type="reset"></button>
    </form>

    <button [ngClass]="{'catBtn mat-btn': true, 'show': listOpacity}" *ngIf="menuOpen && !hidden"
      (click)="catBtnClick()">
      Categories
    </button>

    <app-list [maxHeight]="maxHeight" [listOpacity]="listOpacity"
      [listItems]="showCategoriesList ? categories : listItems" (listItemClick)="onListItemClick($event)"
      *ngIf="menuOpen && !hidden">

    </app-list>

  </div>

</div>